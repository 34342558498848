import React, { ErrorInfo } from "react";

import PageWithIcon from "./Layout/PageWithIcon";
import Person from "../assets/Person2.svg";
import { GenericError } from "./ErrorPage/GenericError";
import { initAdobeAnalytic } from "src/dependencies/adobe-analytic";
import { AwsRum } from "aws-rum-web";

enum InitializationStatus {
  Failure = 0,
  Success = 1,
}

interface PropsType {
  children: JSX.Element;
  rumClient: AwsRum | undefined;
}

/**
 * Simple class to manage the application initialization metric.
 *
 * Note this is meant as a demonstration of how to use the initialization metric, and
 * is almost certainly inadequate for a more complex application.
 *
 * It waits for itself to be mounted (if it is the outermost component that means all of
 * its children are rendered and mounted by React, though are not necessarily fully rendered
 * in the browser), then waits for the translations to be ready.
 *
 * In your actual application, you are likely not fully initialized until you have completed
 * your initial REST calls.  You will need to either integrate that logic here, or else move
 * the initialization metric elsewhere.
 *
 * For applications using a state manager (e.g. Redux) it may make more sense to determine if
 * your application is initialized from that layer instead.
 */

interface State {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

export class AppInitWrapper extends React.Component<PropsType, State> {
  constructor(props: PropsType) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  private isInitialized = false;

  async componentDidMount() {
    this.logInitializationMetric(InitializationStatus.Success);
    await initAdobeAnalytic();
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    this.props.rumClient?.recordEvent("ErrorBoundaryHit", {
      error,
      errorInfo: info,
    });
    this.setState({ hasError: true, error, errorInfo: info });
  }

  render() {
    if (this.state.hasError) {
      return (
        <PageWithIcon src={Person} data-testid="appointment-error-wrapper">
          <GenericError error={this.state.error?.message!} />
        </PageWithIcon>
      );
    }

    return this.props.children;
  }

  // Helper method to publish the initialization metric
  private logInitializationMetric(status: InitializationStatus) {
    if (!this.isInitialized && this.props.rumClient) {
      this.isInitialized = true;
      this.props.rumClient.recordEvent("AppInitialization", {
        status,
      });
    }
  }
}

export default AppInitWrapper;
